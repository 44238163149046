.workitems__container {
  display: flex;
  border-radius: 1rem;
  justify-content: space-around;
  align-items: center;
  align-content: center;
  justify-items: center;
  margin: 5px 5px;
  height: 250px;
  width: 400px;
  background-color: darkblue;
}
.workitems__container:hover {
  display: flex;
  box-shadow: grey 1px 1px 1px 1px;
  background-color: darkblue;
  cursor: pointer;
  align-content: stretch;
  flex-direction: column;
}
.workitems__image {
  height: 100%;
  width: 100%;
  border-radius: 1rem;
}
.hover__div {
  align-content: space-around;
}
.hover__div h3 {
  font-size: 1.5rem;
  font-weight: normal;
  color: white;
  text-align: center;
}
.hover__div p {
  font-size: 0.8rem;
  color: white !important;
  text-align: center;
}
.hover__div a {
  font-size: 1rem;
  font-weight: normal;
  color: white;
  text-align: center;
}

.hover__div a:hover {
  text-decoration: underline;
}
