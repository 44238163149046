.about__container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-height: 75vh;
  overflow-x: hidden;
  overflow-y: auto;
}

.about__container p {
  font-size: 1rem;
  margin-top: 20px;
}
