.work__container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-height: 75vh;
  overflow-x: hidden;
  overflow-y: auto;
  /* padding-left: 20px; */
  padding-top: 15px;
  padding-bottom: 10px;
  justify-content: center;
  align-content: space-between;
}

.work__container p {
  color: red;
}
