* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

h1 {
  color: #426696;
  font-weight: 600;
  font-size: 3rem;
  opacity: 0.8;
}
h2,
p {
  color: #658ec6;
  font-weight: 500;
  opacity: 0.8;
}

h3 {
  color: #426696;
  font-weight: 600;
  opacity: 0.8;
}

.user {
  background-color: transparent;
  border-radius: 5px;
}

main {
  font-family: "Poppins", sans-serif;
  min-height: 100vh;
  background: linear-gradient(to right top, #65dfc9, #6cdbeb);
  /* background: linear-gradient(to right top, #abd2ec, #dceffb); */
  display: flex;
  justify-content: center;
  padding-top: 15px;
  padding-bottom: 15px;
}

.main__container {
  display: flex;
  width: 80vw;
  background: linear-gradient(
    to right bottom,
    rgba(255, 255, 255, 0.7),
    rgba(255, 255, 255, 0.3)
  );
  border-radius: 2rem;
  z-index: 2;
  backdrop-filter: blur(2rem);
}
.circle1,
.circle2 {
  background: white;
  background: linear-gradient(
    to right bottom,
    rgba(255, 255, 255, 0.8),
    rgba(255, 255, 255, 0.3)
  );
  height: 7rem;
  width: 7rem;
  position: absolute;
  border-radius: 50%;
}

.circle1 {
  top: 3%;
  right: 6%;
}
.circle2 {
  bottom: 5%;
  left: 7%;
}
.siderbar__container {
  flex: 1;
  padding-top: 2rem;
  display: flex;
  flex-direction: column;
  background: linear-gradient(
    to right bottom,
    rgba(255, 255, 255, 0.7),
    rgba(255, 255, 255, 0.3)
  );
  border-radius: 2rem;
  justify-content: center;
}
.link {
  display: flex;
  margin: 2rem 0rem;
  padding: 1rem 4rem;
  align-items: center;
  color: #658ec6;
  overflow-wrap: normal;
  transition: width 0.2s, height 0.2s, background-color 0.2s, transform 1s;
}
.link h2 {
  display: flex;
  padding: 0rem 1rem;
  flex-wrap: nowrap;
  overflow-wrap: normal;
  white-space: nowrap;
}
.link:hover {
  /* background: #abd2ec; */
  color: black;
  transform: scale(1.1);
  transition-delay: 0.2s;
  transform-style: inherit;
  transition-timing-function: ease;
}
.link:hover h2 {
  color: black !important;
}
.container {
  margin: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  flex: 3;
  display: flex;
}

@media only screen and (max-width: 1700px) {
  .vertical-timeline-element-date {
    display: block !important;
    float: none !important;
    color: rgb(44, 44, 44);
    margin-top: 1.5em;
  }
}

h3 {
  padding-top: 0.25em;
}

.vertical-timeline-element-content {
  box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.25),
    0 0.4em 1.25em 0 rgba(0, 0, 0, 0.15) !important;
  padding: 2em 3em !important;
  color: #426696;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #65dfc9;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #65dfc9;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #b30000;
}
