.profile__container {
  text-align: center;
}

.profileImg {
  background-color: transparent;
  border-radius: 50%;
  height: 120px;
  width: 120px;
}
