.skillOption__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 1rem;
  background-color: transparent;
  transition: all 0.5s ease-in-out;
  cursor: pointer;
  max-height: 75vh;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 25px 25px 15px 15px;
  flex-direction: column;
}

.skillOption__container:hover {
  transform: scale(1.2);
  transition-delay: 40ms;
  transform-style: inherit;
  transition-timing-function: ease;
}

.skillOption__container img {
  width: 80px;
  height: 80px;
  /* padding-right: 25px;
  padding-left: 25px; */
}
.skillOption__hover {
  display: block;
  padding-top: 15px;
  color: black;
  font-size: 0.75rem;
}
.skillOption__hover--hidden {
  display: none;
}
